import React, { Component } from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Location',
    dataIndex: 'address',
  },
  {
    title: 'Camera',
    dataIndex: 'camera',
  },
  {
    title: 'Total Vehicle Count',
    dataIndex: 'count',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
]
const data = [{
  name : "ATCC1",
  address: "Ajmer",
  camera: "Cam 1",
  count: "471",
  status: "Active"
},
{
  name : "ATCC2",
  address: "Nasirbad",
  camera: "Cam 1",
  count: "513",
  status: "Active"
},
]
export default class ATCCTable extends Component {
  render() {
    return <Table columns={columns} dataSource={data} />
  }
}
