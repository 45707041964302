import React, { Component } from 'react'
import { Table } from 'antd'

import data from './data'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Asset Model',
    dataIndex: 'model',
  },
  {
    title: 'Location',
    dataIndex: 'location',
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    filters: [
      {
        text: 'Active',
        value: 'active',
      },
      {
        text: 'Deactive',
        value: 'deactive',
      },
    ],
  },
]
//const data = [];
export default class AssetsTable extends Component {
  render() {
    return <Table columns={columns} dataSource={data} />
  }
}
