module.exports = [
  {
    id: 'VMS-NHX-001',
    type: 'Variable Message Sign',
    location: '450.317 (LHS)',
    gps: {
      lat: '',
      lng: '',
    },
    model: 'VMS-110-45X170',
    vendor: 'LEDSTAR',
    status: 'Active',
  },
  {
    id: 'ECB-NHX-001',
    type: 'Emergency Calling Box',
    location: '450.317 (LHS)',
    gps: {
      lat: '',
      lng: '',
    },
    model: 'CS-UM-CF-S40VOIP',
    vendor: 'Clearsonics',
    status: 'Active',
  },
  {
    id: 'ATCC-NHX-001',
    type: 'Automatic Traffic Controller cum Classifier (ATCC)',
    location: '450.317 (LHS)',
    gps: {
      lat: '',
      lng: '',
    },
    model: 'ph01',
    vendor: 'PHONIEX',
    status: 'Active',
  },
  {
    id: 'MDS-NHX-001',
    type: 'Metrological Data System',
    location: '450.317 (LHS)',
    gps: {
      lat: '',
      lng: '',
    },
    model: 'SSI',
    vendor: 'Quixote',
    status: 'Active',
  },
  {
    id: 'CAM-NHX-001',
    type: 'PTZ Camera',
    location: '450.317 (LHS)',
    gps: {
      lat: '',
      lng: '',
    },
    model: 'DS-2DE7230IW-AE',
    vendor: 'Hikvision',
    status: 'Active',
  },
  {
    id: 'Vidserv-NHX-001',
    type: 'Video server',
    location: '450.317 (LHS)',
    gps: {
      lat: '',
      lng: '',
    },
    model: 'DS-6101HFI-IP-A',
    vendor: 'Hikvision',
    status: 'Active',
  },
  {
    id: 'CC-NHX-001',
    type: 'ATMS Control center',
    location: '450.317 (LHS)',
    gps: {
      lat: '',
      lng: '',
    },
    model: 'DL 380 G5',
    vendor: 'HP',
    status: 'Active',
  },
]
