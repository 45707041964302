import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Typography, Table, Button, Select, Carousel } from 'antd'
import './vms.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { changeTheme } from "../../utils/theme-utils"
import data from './data.json'

const { Text } = Typography
const { Option } = Select
const buttonStyle = {
    backgroundColor: "#797979",
    color: "white", 
    width: "100%",
    height: "80px",
  };

class VMS extends Component {
  static propTypes = {}
  state = {
    newMessage: '',
    data: data.data,
    vms: {
      id: null,
      name: '---',
      location: '---, ---',
      status: '---',
      connection: '---',
      brightness: '---',
      message: ['No VMS Selected!'],
    },
    messages: [
      "Don't Drink and Drive",
      "Don't use mobile while driving",
      'Drive safely family is in wating',
      'Work in progress go slowly',
      'Don\'t over- speed',
      'Wear your seat belt',
      'Obey all traffic rules',
      'Keep your car in good running shape',
    ],
  }

  pushMessage = () => {
    const data = this.state.data
    data.forEach((obj, index) => {
      if (index === this.state.vms.id) {
        obj.message = [this.state.newMessage]
      }
    })
    const vms = this.state.vms
    vms.message = [this.state.newMessage]
    this.setState({
      vms,
      data,
      newMessage: '',
    })
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col span={8} className="bg">
            VMS Name : {this.state.vms.name}
          </Col>
          <Col span={8} className="bg">
            Connection :{' '}
            <FontAwesomeIcon
              icon={['fas', 'circle']}
              color={
                this.state.vms.connection === 'connected'
                  ? 'green'
                  : this.state.vms.connection === '---'
                  ? 'grey'
                  : 'red'
              }
              fontSize="0.9rem"
            />
          </Col>
          <Col span={8} className="bg">
            Status : {this.state.vms.status}
          </Col>
          <Col span={8} className="bg">
            Location : {this.state.vms.location}
          </Col>
          <Col span={8} className="bg">
            Brightness : {this.state.vms.brightness}
          </Col>
        </Row>
        <Carousel style={{ textAlign: 'center' }} dotPosition="bottom" autoplay>
          {this.state.vms.message.length ? (
            this.state.vms.message.map((msg, index) => {
              return (
                <div
                  className="vms-box square-background-white"
                  key={index}
                  style={{
                    display: 'flex',
                  }}
                >
                  <Text style={{ color: '#434343' }} strong>
                    {msg}
                  </Text>
                </div>
              )
            })
          ) : (
            <div
              className="vms-box square-background-white"
              style={{
                display: 'flex',
              }}
            >
              <Text style={{ color: '#434343' }} strong>
                No Message!
              </Text>
            </div>
          )}
        </Carousel>
        <Row style={{ margin: '1rem 0 2rem 0' }}>
          <Col span={17}>
            <Select
              defaultValue={'No Message'}
              style={{ width: '100%' }}
              onChange={(value) => {
                this.setState({ newMessage: value })
              }}
              {...(this.state.vms.name === '---' ? { disabled: true } : {})}
            >
              <Option value={'No Message'}>---</Option>
              {this.state.messages.map((obj, index) => (
                <Option value={obj} key={index}>
                  {obj}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Button
              type="primary"
              {...(this.state.vms.name === '---' ? { disabled: true } : {})}
              onClick={this.pushMessage}
            >
              Push Message
            </Button>
          </Col>
        </Row>
        <Row >
          <Col span={6}><Button style={{ ...buttonStyle, color: "#00FF00" }}>12<br/>Active</Button></Col>
          <Col span={6}><Button style={{ ...buttonStyle, color: "#ff0000" }}>6<br/>Inactive</Button></Col>
          <Col span={6}><Button style={{ ...buttonStyle, color: "orange" }}>3<br/>Maintenance</Button></Col>
          <Col span={6}><Button style={{ ...buttonStyle, color: "blue" }}>21<br/>All</Button></Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={data.columns}
              dataSource={this.state.data}
              className="bg"
              width="100%"
              onRow={(vmsData, index) => {
                return {
                  onClick: () => {
                    let data = vmsData
                    data.id = index
                    this.setState({ vms: data })
                  },
                }
              }}
              style={{ overflow: 'hidden' }}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = () => ({})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(VMS)
