import React from 'react'
import {
  Form,
  Input,
  Button,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  Switch,
  Row,
  Col,
} from 'antd'
import { Link } from 'react-router-dom'

const AddAsset = () => {
  return (
    <div style={{ padding: '10px' }} className="bg">
      <h3>Add Asset</h3>
      <Row className="bg">
        <Col span={24}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            className="bg"
          >
            <Form.Item label="ID" help="Will be auto generated">
              <Input disabled />
            </Form.Item>
            <Form.Item label="Asset Type" help="Select type of the asset">
              <Select className="bg">
                <Select.Option value="ecb">Emergency Call Box</Select.Option>
                <Select.Option value="vms">Variable Message Sign</Select.Option>
                <Select.Option value="atcc">
                  Automatic Traffic Controller cum Classifier (ATCC)
                </Select.Option>
                <Select.Option value="mds">
                  Metrological Data System
                </Select.Option>
                <Select.Option value="ptz">PTZ Camera</Select.Option>
                <Select.Option value="atms">ATMS Control center</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Location" help="Location chainage, LHS/RHS">
              <Row>
                <Col span={16}>
                  <InputNumber style={{ width: '100%' }} />
                </Col>
                <Col span={8}>
                  <Select>
                    <Select.Option value="lhs">LHS</Select.Option>
                    <Select.Option value="rhs">RHS</Select.Option>
                  </Select>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="lat" help="latitude of the asset">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="lng" help="longitude of the asset">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label="Model"
              help="Select from list of models integrated"
            >
              <Cascader
                options={[
                  {
                    value: 'hikvision-ptz',
                    label: 'Hikvision',
                    children: [
                      {
                        value: 'DS-2DE7230IW-AE',
                        label: 'DS-2DE7230IW-AE',
                      },
                      {
                        value: 'DS-2DE8230IW-AE',
                        label: 'DS-2DE8230IW-AE',
                      },
                    ],
                  },
                  {
                    value: 'bosch-ptz',
                    label: 'Bosch',
                    children: [
                      {
                        value: 'DS-2DE7230IW-AE',
                        label: 'DS-2DE7230IW-AE',
                      },
                      {
                        value: 'DS-2DE8230IW-AE',
                        label: 'DS-2DE8230IW-AE',
                      },
                    ],
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Vendor"
              help="Vendor from where device is procured"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Status"
              help="Select whether the device is available or not"
            >
              <Switch />
            </Form.Item>
            <Form.Item label="Purchase Date" help="Purchase date of the asset">
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Price" help="Purchase price">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="ip" help="ip address of the asset">
              <Input />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="end" className="bg">
        <Col span={2} offset={20}>
          <Link to="/assets">
            <Button type="primary">Save</Button>
          </Link>
        </Col>
      </Row>
    </div>
  )
}

export default AddAsset
