import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Statistic, Card, Image, Typography, Button } from 'antd'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import jaipur from './jaipur.jpg'
// import ReactSpeedometer from "react-d3-speedometer";

const { Text } = Typography

class Met extends Component {
  static propTypes = {}

  state = {
    wis: {
      id: null,
      name: '---',
      location: '---, ---',
      status: '---',
      connection: 'connected',
    },
    location: 'Rajasthan',
    windSpeed: 10,
    minWindSpeed: 7,
    maxWindSpeed: 12,
    minBatteryLevel: 4,
    batteryLevel: 65,
    maxBatteryLevel: 100,
    avgBatteryLevel: 55,
    minAirTemp: 20,
    airTemp: 24,
    maxAirTemp: 28,
    avgAirTemp: 25,
    irsTemp: 24,
    minIRSTemp: 21,
    avgVisibility: 20,
    visibility: 16,
    avgWindDirection: 240,
    maxWindDirection: 260,
    windDirection: 210,
    currentTempIcon: 'thermometer-empty',
  }

  componentDidMount() {
    setInterval(() => {
      if (this.state.currentTempIcon === 'thermometer-empty') {
        this.setState({ currentTempIcon: 'thermometer-quarter' })
      } else if (this.state.currentTempIcon === 'thermometer-quarter') {
        this.setState({ currentTempIcon: 'thermometer-half' })
      } else if (this.state.currentTempIcon === 'thermometer-half') {
        this.setState({ currentTempIcon: 'thermometer-three-quarters' })
      } else if (this.state.currentTempIcon === 'thermometer-three-quarters') {
        this.setState({ currentTempIcon: 'thermometer-full' })
      } else {
        this.setState({ currentTempIcon: 'thermometer-empty' })
      }
    }, 1000)
  }

  render() {
    const buttonStyle = {
      backgroundColor: "#797979",
      color: "white", 
      width: "100%",
      height: "80px",
    };

    return (
      <React.Fragment>
        <Row type="flex" justify="start">
          <Col>
            <h2>Metrological Station</h2>
          </Col>
        </Row>
        <Row>
          <Col span={8} className="bg">
            Connection :{' '}
            <FontAwesomeIcon
              icon={['fas', 'circle']}
              color={
                this.state.wis.connection === 'connected'
                  ? 'green'
                  : this.state.wis.connection === '---'
                  ? 'grey'
                  : 'red'
              }
              fontSize="0.9rem"
            />
          </Col>
          <Col span={8} className="bg">
            Status : {this.state.wis.status}
          </Col>
          <Col span={8} className="bg">
            Location : {this.state.wis.location}
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <div className="met-img">
              <div className="met-img-top">
                <div className="met-location-left">{this.state.location}</div>
                <div
                  className="met-location-right"
                  style={{ textAlign: 'right' }}
                >
                  <div>
                    <FontAwesomeIcon
                      size={'0.9rem'}
                      icon={['fas', 'map-marker-alt']}
                    />
                  </div>
                  <div>Rajasthan, India</div>
                  <div>9:41 AM</div>
                </div>
              </div>
              <Image
                alt="camera-preview"
                width="100%"
                height="100%"
                src={jaipur}
              />
              <div className="met-img-bottom">
                <div className="met-img-weather">
                  <FontAwesomeIcon icon={['fas', 'sun']} className="spin" />
                  <div>Sunny</div>
                </div>
                <div>
                  <div className="met-img-scard-top">Wednesday</div>
                  <div className="met-img-scard-mid">29&deg;</div>
                </div>
                <div>
                  <div className="met-img-scard-top">Wed</div>
                  <div className="met-img-scard-mid">
                    <FontAwesomeIcon icon={['fas', 'cloud-sun-rain']} />
                  </div>
                  <div className="met-img-scard-bottom">20&deg;</div>
                </div>
                <div>
                  <div className="met-img-scard-top">Thu</div>
                  <div className="met-img-scard-mid">
                    <FontAwesomeIcon icon={['fas', 'cloud-sun']} />
                  </div>
                  <div className="met-img-scard-bottom">24&deg;</div>
                </div>
                <div>
                  <div className="met-img-scard-top">Fri</div>
                  <div className="met-img-scard-mid">
                    <FontAwesomeIcon icon={['fas', 'cloud-sun-rain']} />
                  </div>
                  <div className="met-img-scard-bottom">22&deg;</div>
                </div>
                <div>
                  <div className="met-img-scard-top">Sat</div>
                  <div className="met-img-scard-mid">
                    <FontAwesomeIcon icon={['fas', 'sun']} className="spin" />
                  </div>
                  <div className="met-img-scard-bottom">29&deg;</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{paddingTop: "10px", paddingBottom: "10px"}}>
          <Col span={6}><Button style={{ ...buttonStyle, color: "#00FF00" }}>12<br/>Active</Button></Col>
          <Col span={6}><Button style={{ ...buttonStyle, color: "#ff0000" }}>6<br/>Inactive</Button></Col>
          <Col span={6}><Button style={{ ...buttonStyle, color: "orange" }}>3<br/>Maintenance</Button></Col>
          <Col span={6}><Button style={{ ...buttonStyle, color: "blue" }}>21<br/>All</Button></Col>
        </Row>
        <div
          className="site-statistic-demo-card"
          style={{ margin: '0rem auto' }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Card style={{ border: '0px' }}>
                <div
                  style={{ display: 'grid', gridTemplateColumns: '1.5fr 1fr' }}
                >
                  <Statistic
                    title="Air Temperature"
                    value={this.state.airTemp}
                    precision={1}
                    prefix={
                      <FontAwesomeIcon
                        color="#cf1322"
                        icon={['fas', this.state.currentTempIcon]}
                      />
                    }
                    suffix={<>&deg;C</>}
                  />
                  <div
                    className="met-card-bottom"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <Text type="secondary">
                        Min: {this.state.minAirTemp}
                        <sup>&deg;</sup>
                      </Text>
                    </div>
                    <div>
                      <Text type="secondary">
                        Max: {this.state.maxAirTemp}
                        <sup>&deg;</sup>
                      </Text>
                    </div>
                    <div>
                      <Text type="secondary">
                        Avg: {this.state.avgAirTemp}
                        <sup>&deg;</sup>
                      </Text>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ border: '0px' }}>
                <Statistic
                  title="Wind Speed"
                  value={this.state.windSpeed}
                  prefix={
                    <FontAwesomeIcon
                      icon={['fas', 'fan']}
                      color="lightseagreen"
                      className="spin"
                    />
                  }
                  suffix="km/h"
                />
                <div className="met-card-bottom">
                  <div>
                    <Text type="secondary">
                      Min : {this.state.minWindSpeed}{' '}
                      <sup className="sup-small">km/h</sup>
                    </Text>
                  </div>
                  <div>
                    <Text type="secondary">
                      Max : {this.state.maxWindSpeed}{' '}
                      <sup className="sup-small">km/h</sup>
                    </Text>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <div
          className="site-statistic-demo-card"
          style={{ margin: '0rem auto' }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Card style={{ border: '0px' }}>
                <Statistic
                  title="IRS Road Temp"
                  value={this.state.irsTemp}
                  precision={1}
                  prefix={
                    <FontAwesomeIcon
                      color="#cf1322"
                      icon={['fas', this.state.currentTempIcon]}
                    />
                  }
                  // prefix={<AnimatedThermometer />}
                  suffix={<>&deg;C</>}
                />
                <div
                  className="met-card-bottom"
                  style={{ justifyContent: 'left' }}
                >
                  <div>
                    <Text type="secondary">
                      Avg: {this.state.minIRSTemp}
                      <sup>%</sup>
                    </Text>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ border: '0px' }}>
                <Statistic
                  title="Wind Direction"
                  value={this.state.windDirection}
                  precision={0}
                  suffix={<>&deg;</>}
                  prefix={
                    <FontAwesomeIcon
                      icon={['fas', 'compass']}
                      color="#207398"
                    />
                  }
                />
                <div className="met-card-bottom">
                  <div>
                    <Text type="secondary">
                      Max: {this.state.maxWindDirection}
                      <sup>%</sup>
                    </Text>
                  </div>
                  <div>
                    <Text type="secondary">
                      Avg: {this.state.avgWindDirection}
                      <sup>%</sup>
                    </Text>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <div
          className="site-statistic-demo-card"
          style={{ margin: '0rem auto' }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Card style={{ border: '0px' }}>
                <Statistic
                  title="Visibility"
                  precision={0}
                  value={this.state.visibility}
                  suffix="KM"
                  prefix={
                    <FontAwesomeIcon icon={['fas', 'eye']} color="#096dd9" />
                  }
                />
                <div
                  className="met-card-bottom"
                  style={{ justifyContent: 'left' }}
                >
                  <div>
                    <Text type="secondary">
                      Avg: {this.state.avgVisibility}
                      <sup>KM</sup>
                    </Text>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                style={{
                  border: '0px',
                }}
              >
                <div>
                  <Statistic
                    title="Relative Humidity"
                    value={93}
                    prefix={
                      <FontAwesomeIcon
                        icon={['fas', 'tint']}
                        color="lightblue"
                      />
                    }
                    suffix="%"
                  />
                  <div
                    className="met-card-bottom"
                    style={{ justifyContent: 'left' }}
                  >
                    <div>
                      <Text type="secondary">
                        Avg: {this.state.minAirTemp}
                        <sup>%</sup>
                      </Text>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <div
          className="site-statistic-demo-card"
          style={{ margin: '0rem auto' }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Card style={{ border: '0px' }}>
                <div
                  style={{ display: 'grid', gridTemplateColumns: '1.5fr 1fr' }}
                >
                  <Statistic
                    title="Rain Fall"
                    value={this.state.batteryLevel}
                    precision={0}
                    suffix="cm"
                    prefix={
                      <FontAwesomeIcon
                        icon={['fas', 'cloud-rain']}
                        color="#207398"
                      />
                    }
                  />
                  <div
                    className="met-card-bottom"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      // alignItems: 'center',
                      textAlign: 'justify !important',
                    }}
                  >
                    <div>
                      <Text type="secondary">
                        Min: {this.state.minBatteryLevel}
                        <sup>cm</sup>
                      </Text>
                    </div>
                    <div>
                      <Text type="secondary">
                        Max: {this.state.maxBatteryLevel}
                        <sup>cm</sup>
                      </Text>
                    </div>
                    <div>
                      <Text type="secondary">
                        Avg: {this.state.avgBatteryLevel}
                        <sup>cm</sup>
                      </Text>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ border: '0px' }}>
                <Statistic
                  title="Battery"
                  value={this.state.batteryLevel}
                  precision={0}
                  suffix="%"
                  prefix={
                    <FontAwesomeIcon
                      icon={['fas', 'battery-full']}
                      color="#52c41a"
                    />
                  }
                />
                {/* <div className="met-card-bottom">
                  <div>
                    <Text type="secondary">
                      Min: {this.state.minBatteryLevel}
                      <sup>%</sup>
                    </Text>
                  </div>
                  <div>
                    <Text type="secondary">
                      Max: {this.state.maxBatteryLevel}
                      <sup>%</sup>
                    </Text>
                  </div>
                  <div>
                    <Text type="secondary">
                      Avg: {this.state.avgBatteryLevel}
                      <sup>%</sup>
                    </Text>
                  </div>
                </div> */}
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = () => ({})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(Met)
