import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Carousel } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
// import Icon from '@ant-design/icons';
import vms1 from '../../assets/vms/01.png'
import vms2 from '../../assets/vms/02.png'
import vms3 from '../../assets/vms/03.png'
import batteryIcon from '../../assets/icons/battery.svg'

class VMSInfo extends Component {
  static propTypes = {}

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <Row type="flex" justify="start" className="bg">
          <Col className="bg">
            <h2>Variable Message Sign</h2>
          </Col>
        </Row>

        <Row className="bg"></Row>
        <Row style={{ textAlign: 'center' }} className="bg">
          <Col>
            <img
              alt="camera-preview"
              height="200px"
              style={{ padding: '20px' }}
              src="https://www.cctvview.info/camimages/82f36608a1e888a4f480d83083ccd302.jpg"
            />
          </Col>
        </Row>
        <Row type="flex" justify="start" className="bg">
          <Col span="12">
            <b>Battery Status:</b>
          </Col>
          <Col>
            <img
              style={{ color: 'green', height: '18px' }}
              src={batteryIcon}
              alt=""
            />
          </Col>
        </Row>
        <Row type="flex" justify="start" className="bg">
          <Col span="12">
            <b>Resolution:</b>
          </Col>
          <Col></Col>
        </Row>
        <Row type="flex" justify="start" className="bg">
          <Col span="12">
            <b>Messages:</b>
          </Col>
          <Col>
            <Carousel dotPosition="top" className="bg" autoplay>
              <img style={{ height: '150px' }} src={vms1} alt="img" />
              <img style={{ height: '150px' }} src={vms2} alt="img" />
              <img style={{ height: '150px' }} src={vms3} alt="img" />
            </Carousel>
          </Col>
        </Row>
        <Row type="flex" justify="start" className="bg">
          <Col span="12">
            <b>Type:</b>
          </Col>
          <Col></Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span="12">
            <b>Location:</b>
          </Col>
          <Col></Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span="12">
            <b>VMS Connection Status:</b>
          </Col>
          <Col>
            <CheckCircleOutlined style={{ color: 'green' }} />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = () => ({})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(VMSInfo)
