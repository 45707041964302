import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  // AlertOutlined,
  // MailOutlined,
  // SettingOutlined,
  // ToolOutlined,
  // VideoCameraOutlined,
  BellOutlined,
} from '@ant-design/icons'

import { Menu, Modal } from 'antd'
import { LiveDateTime } from '../../utils/react-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function LogOutMsg() {
  Modal.info({
    title: 'Log-out',
    content: (
      <div>
        <p>Log-out under Construction! Sorry for Inconvenience :-(</p>
      </div>
    ),
    onOk() {},
  })
}

export default class TopMenu extends Component {
  state = {
    current: 'mail',
    theme: 'light',
  }

  handleClick = (e) => {
    console.log('click ', e)
    this.setState({
      current: e.key,
    })
  }

  componentDidMount = () => {
    // const localStorage = window.localStorage
    // this.changeTheme(localStorage.getItem("theme") ? localStorage.getItem("theme") : this.state.theme)
  }

  // changeTheme = (theme) => {
  //   // const theme = window.localStorage.getItem("theme") ? window.localStorage.getItem("theme") : "light";
  //   elementTreeToggler(theme === "dark" ? "dark" : "light")
  //   window.localStorage.setItem("theme", theme)
  //   this.setState({theme: theme})
  //   return theme;
  // }

  render() {
    return (
      <>
        <Menu
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
          mode="horizontal"
          className="bg _topmenu"
        >
          <Menu.Item key="date-time">
            <LiveDateTime />
          </Menu.Item>
          <Menu.SubMenu
            title={<BellOutlined style={{ fontSize: '1.25rem' }} />}
            key="/bell"
            style={{ textAlign: 'center' }}
          >
            <Menu.Item key="1">
              <Link to="#">Test Notification is Here 1</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="#">Test Notification is Here 2</Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="#">Test Notification is Here 3</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="alipay">
            <span
              onClick={LogOutMsg}
              rel="noreferrer"
              className="bg-text-primary "
            >
              <FontAwesomeIcon
                icon={['fa', 'power-off']}
                style={{ fontSize: '1.1rem' }}
              />
            </span>
          </Menu.Item>
        </Menu>
      </>
    )
  }
}
