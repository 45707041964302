import React, { Component } from 'react'
import moment from 'moment'
import {
  DatePicker,
  Table,
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Select,
  Modal,
} from 'antd'
import reportSelectOption from './reportSelectOption.json'

import cctv from './data/cctv.json'
import vms from './data/vms.json'
import vmsMsg from './data/msg.json'
import vehicalIntensity from './data/vehicalIntensity.json'
import laneHour from './data/laneHour.json'
import ecbAlarm from './data/ecbAlarm.json'
import ecbEvent from './data/ecbEvent.json'
import mds from './data/mds.json'

const { Content } = Layout
const { RangePicker } = DatePicker
const { Title } = Typography
const { Option } = Select

// function getTime(dtString, format="YYYY/MM/DD HH"){
//     const objDate = moment(dtString, format)
//     const dt = new Date(objDate.year(), objDate.month(), objDate.date(), objDate.hour());
//     return(dt);
// }

function filterByDateTime(
  fromDate,
  toDate,
  uid,
  data = [],
  filter_criteria = 'month',
) {
  // const interval = {
  //     month: 30*24*60*60,
  //     week: 7*24*60*60,
  //     day: 24*60*60,
  //     hour: 60*60
  // }
  // const output = []
  const unique = []
  data.forEach((obj) => {
    if (!unique.includes(obj[uid])) {
      unique.push([obj[uid]])
    }
  })
  // const newData = {}

  return data
}

class Reports extends Component {
  currentDate = new Date()
  state = {
    fromDate: `${this.currentDate.getFullYear()}/${this.currentDate.getMonth()}/${this.currentDate.getDate()} ${this.currentDate.getHours()}`,
    toDate: `${this.currentDate.getFullYear()}/${
      this.currentDate.getMonth() + 1
    }/${this.currentDate.getDate()} ${this.currentDate.getHours()}`,
    columns: [],
    data: [],
    visible: false,
    selectOptions: reportSelectOption.option,
    dataSource: '',
    deviceType: 'cctv',
    reportType: 'hour',
    deviceTypes: {
      cctv: [],
      vms: [
        {
          key: 'vms_message',
          text: 'VMS Message',
        },
        {
          key: 'vms_history',
          text: 'VMS History',
        },
      ],
      mds: [],
      atcc: [
        {
          key: 'lane',
          text: 'Lane',
        },
        {
          key: 'vehical_intensity',
          text: 'Vehical Intensity',
        },
      ],
      ecb: [
        {
          key: 'ecb_alarm_history',
          text: 'ECB Alarm History',
        },
        {
          key: 'ecb_event',
          text: 'ECB Event',
        },
      ],
    },
  }

  changeDataSource = (value) => {
    switch (value) {
      case 'cctv':
        const dataCCTV = filterByDateTime(
          this.state.fromDate,
          this.state.toDate,
          'equipment',
          cctv.data,
          this.state.reportType,
        )
        this.setState({
          columns: cctv.column,
          data: dataCCTV,
        })
        break
      case 'mds':
        const dataMds = filterByDateTime(
          this.state.fromDate,
          this.state.toDate,
          'weather_station',
          mds.data,
          this.state.reportType,
        )
        this.setState({
          columns: mds.column,
          data: dataMds,
        })
        break
      case 'ecb_alarm_history':
        const dataEcbAlarm = filterByDateTime(
          this.state.fromDate,
          this.state.toDate,
          'ecb',
          ecbAlarm.data_historic,
          this.state.reportType,
        )
        this.setState({
          columns: ecbAlarm.column,
          data: dataEcbAlarm,
        })
        break
      case 'ecb_event':
        const dataEcbEvent = filterByDateTime(
          this.state.fromDate,
          this.state.toDate,
          'ecb',
          ecbEvent.data,
          this.state.reportType,
        )
        this.setState({
          columns: ecbEvent.column,
          data: dataEcbEvent,
        })
        break
      case 'lane':
        const dataLane = filterByDateTime(
          this.state.fromDate,
          this.state.toDate,
          'lane',
          laneHour.data,
          this.state.reportType,
        )
        this.setState({
          columns: laneHour.column,
          data: dataLane,
        })
        break
      case 'vehical_intensity':
        const dataVI = filterByDateTime(
          this.state.fromDate,
          this.state.toDate,
          'section',
          vehicalIntensity.data,
          this.state.reportType,
        )
        this.setState({
          columns: vehicalIntensity.column,
          data: dataVI,
        })
        break
      case 'vms_message':
        const dataVM = filterByDateTime(
          this.state.fromDate,
          this.state.toDate,
          'location',
          vmsMsg.data,
          this.state.reportType,
        )
        this.setState({
          columns: vmsMsg.column,
          data: dataVM,
        })
        break
      case 'vms_history':
        const dataVH = filterByDateTime(
          this.state.fromDate,
          this.state.toDate,
          'equipment',
          vms.data,
          this.state.reportType,
        )
        this.setState({
          columns: vms.column,
          data: dataVH,
        })
        break
      default:
        this.setState({
          column: [],
          data: [],
        })
        break
    }
  }

  render() {
    return (
      <Layout theme={'light'} style={{ backgroundColor: '#ffffff' }}>
        <div
          style={{
            // backgroundColor: 'rgb(218, 218, 218)',
            paddingLeft: '5rem',
            height: 'min-height',
          }}
        >
          <Title
            level={4}
            style={{
              display: 'inline-block',
              height: 'min-content',
              backgroundColor: 'snow',
              marginBottom: '0px',
              padding: '0.1rem 0.1rem',
            }}
          >
            Reports
          </Title>
        </div>
        <Row
          style={{
            margin: '1rem 0',
            backgroundColor: '#ffffff',
            padding: '2rem 3rem 0rem 5rem',
            fontWeight: 'normal',
          }}
        >
          <Col span={4} style={{ fontSize: '1.1rem' }}>
            Select Device Type
          </Col>
          <Col span={8}>
            <Select
              defaultValue="select_device"
              style={{
                width: '50%',
                // marginLeft: "1rem",
                marginRight: '0.5rem',
              }}
              onChange={(value) => {
                if (value !== 'select_device') {
                  const newState = {
                    deviceType: value,
                    dataSource: value,
                  }
                  const subs = ['ecb', 'vms', 'atcc']
                  if (subs.includes(value)) {
                    newState.dataSource = this.state.deviceTypes[value][0].key
                  }
                  this.setState(newState)
                } else {
                  this.setState({
                    deviceType: 'select_device',
                  })
                }
              }}
            >
              <Option value="select_device" key="select_device">
                Select Device
              </Option>
              {this.state.selectOptions.map((opt, index) => {
                return (
                  <Option value={opt.key} key={index}>
                    {opt.text}
                  </Option>
                )
              })}
            </Select>
          </Col>
          {/* Sub Menu Starts Here */}
          {this.state.deviceType === 'vms' ? (
            <>
              <Col span={5} style={{ fontSize: '1.1rem' }}>
                Select Sub Device Type
              </Col>
              <Col span={7}>
                <Select
                  defaultValue={this.state.deviceTypes['vms'][0].key}
                  style={{
                    width: '55%',
                    marginRight: '0.5rem',
                  }}
                  onChange={(value) => {
                    this.setState({
                      dataSource: value,
                    })
                  }}
                >
                  {this.state.deviceTypes['vms'].map((opt, index) => {
                    return (
                      <Option value={opt.key} key={index}>
                        {opt.text}
                      </Option>
                    )
                  })}
                </Select>
              </Col>
            </>
          ) : (
            ''
          )}
          {this.state.deviceType === 'ecb' ? (
            <>
              <Col span={5} style={{ fontSize: '1.1rem' }}>
                Select Sub Device Type
              </Col>
              <Col span={7}>
                <Select
                  defaultValue={this.state.deviceTypes['ecb'][0].key}
                  style={{
                    width: '55%',
                  }}
                  onChange={(value) => {
                    this.setState({
                      dataSource: value,
                    })
                  }}
                >
                  {this.state.deviceTypes['ecb'].map((opt, index) => {
                    return (
                      <Option value={opt.key} key={index}>
                        {opt.text}
                      </Option>
                    )
                  })}
                </Select>
              </Col>
            </>
          ) : (
            ''
          )}
          {this.state.deviceType === 'atcc' ? (
            <>
              <Col span={5} style={{ fontSize: '1.1rem' }}>
                Select Sub Device Type
              </Col>
              <Col span={7}>
                <Select
                  defaultValue={this.state.deviceTypes['atcc'][0].key}
                  style={{
                    width: '55%',
                    marginRight: '0.5rem',
                  }}
                  onChange={(value) => {
                    this.setState({
                      dataSource: value,
                    })
                  }}
                >
                  {this.state.deviceTypes['atcc'].map((opt, index) => {
                    return (
                      <Option value={opt.key} key={index}>
                        {opt.text}
                      </Option>
                    )
                  })}
                </Select>
              </Col>
            </>
          ) : (
            ''
          )}

          {/* Sub Menu Ends Here */}
        </Row>
        <Row
          style={{
            margin: '1rem 0',
            backgroundColor: '#ffffff',
            padding: '0rem 3rem 0.5rem 5rem',
            fontWeight: 'normal',
          }}
        >
          <Col span={4} style={{ fontSize: '1.1rem' }}>
            Select Type of Report
          </Col>
          <Col span={20}>
            <Select
              defaultValue="month"
              style={{
                width: '20%',
                // marginLeft: "1rem",
                marginRight: '0.5rem',
              }}
              onChange={(val) => {
                if (val !== 'select_report_type') {
                  this.setState({ reportType: val })
                }
              }}
            >
              <Option value="select_report_type" key="select_report_type">
                Select Report Type
              </Option>
              <Option value="hour" key="hour">
                Hour
              </Option>
              <Option value="day" key="day">
                Day
              </Option>
              <Option value="week" key="week">
                Week
              </Option>
              <Option value="month" key="month">
                Month
              </Option>
            </Select>
          </Col>
        </Row>
        <Row
          style={{
            margin: '1rem 0',
            backgroundColor: '#ffffff',
            padding: '0rem 3rem 0.5rem 5rem',
            fontWeight: 'normal',
          }}
        >
          <Col span={4} style={{ fontSize: '1.1rem' }}>
            Select date
          </Col>
          <Col span={8}>
            <RangePicker
              defaultValue={[
                moment(this.state.fromDate, 'YYYY/MM/DD HH'),
                moment(this.state.toDate, 'YYYY/MM/DD HH'),
              ]}
              format={'DD/MM/YYYY HH'}
              showTime={true}
              onChange={(value) => {
                let fromYear = value[0].year()
                let fromMonth =
                  value[0].month() + 1 < 10
                    ? `0${value[0].month() + 1}`
                    : value[0].month() + 1
                let fromDate =
                  value[0].date() < 10 ? `0${value[0].date()}` : value[0].date()
                let fromHour =
                  value[0].hour() < 10 ? `0${value[0].hour()}` : value[0].hour()

                let toYear = value[1].year()
                let toMonth =
                  value[1].month() + 1 < 10
                    ? `0${value[1].month() + 1}`
                    : value[1].month() + 1
                let toDate =
                  value[1].date() < 10 ? `0${value[1].date()}` : value[1].date()
                let toHour =
                  value[1].hour() < 10 ? `0${value[1].hour()}` : value[1].hour()
                const newState = {
                  fromDate: `${fromYear}/${fromMonth}/${fromDate} ${fromHour}`,
                  toDate: `${toYear}/${toMonth}/${toDate} ${toHour}`,
                }

                this.setState(newState)
              }}
            />
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              onClick={() => {
                if (this.state.deviceType !== 'select_device') {
                  this.changeDataSource(this.state.dataSource)
                } else {
                  this.setState({
                    data: [],
                    columns: [],
                  })
                }
              }}
            >
              Search
            </Button>
          </Col>
          <Col span={10} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              style={{
                marginLeft: '0.5rem',
                backgroundColor: 'rgb(23, 139, 38)',
              }}
              onClick={() => {
                this.setState({ visible: true })
              }}
            >
              Export as Excel
            </Button>
            <Button
              type="primary"
              style={{
                marginLeft: '0.5rem',
                backgroundColor: 'rgb(64, 0, 184)',
              }}
              onClick={() => {
                this.setState({ visible: true })
              }}
            >
              Export as PDF
            </Button>
          </Col>
        </Row>
        <Content>
          {this.state.columns.length ? (
            <Table columns={this.state.columns} dataSource={this.state.data} />
          ) : (
            <Table />
          )}
        </Content>
        <Modal
          title={'Under Construction!'}
          visible={this.state.visible}
          onOk={() => {
            this.setState({ visible: false })
          }}
          onCancel={() => {
            this.setState({ visible: false })
          }}
        >
          You will be able to download data soon...
        </Modal>
      </Layout>
    )
  }
}

export default Reports
