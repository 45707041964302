import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import {
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons'

import { Row, Col, Slider, Button, Image } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CameraTable from './Table'

// import {changeTheme} from "../../utils/theme-utils"
let stream = new MediaStream();

let config = {
  iceServers: [{
    urls: ["stun:stun.l.google.com:19302"]
  }]
};
class Video extends Component {
  // componentDidMount = () => {
  //   changeTheme()
  // }

  constructor(props) {
    super(props)
    this.pc = new RTCPeerConnection(config);
    this.videoRef = React.createRef()

    // this.state = {
    //   videoSrc: null,
    // }
  }

  componentDidMount() {
    this.getCodecInfo()
    this.pc.onnegotiationneeded = this.handleNegotiationNeededEvent;
    this.pc.ontrack = (event) => {
      stream.addTrack(event.track);
      //videoElem.srcObject = stream;
      // /this.setState({videoSrc: stream})
      //if (this.videoRef.current.srcObject !== this.props.stream) {
        this.videoRef.current.srcObject = stream
      //}
      console.log(event.streams.length + ' track is delivered')
    }
    
  }
   
  handleNegotiationNeededEvent = () => {
    this.pc.createOffer().then(offer => {
      this.pc.setLocalDescription(offer).then(result => {
        this.getRemoteSdp();
      });
    });
    
  }

  getRemoteSdp = () => {
    debugger;
    const formData = new FormData()
    formData.append("data", btoa(this.pc.localDescription.sdp))
    formData.append("suuid", "test")

    axios.post("http://localhost:8000/api/cam/stream/receiver/test", formData).then(res => {
      debugger;
      try {
        this.pc.setRemoteDescription(new RTCSessionDescription({
          type: 'answer',
          sdp: atob(res.data)
        }))
      } catch (e) {
        console.warn(e);
      }
    });
  }

  getCodecInfo = () => {
    axios.get("http://localhost:8000/api/cam/stream/codec/test").then(res => {
        res.data.forEach((value) => {
          this.pc.addTransceiver(value.Type, {
            'direction': 'sendrecv'
          })
        })
    });
  }
  

  static propTypes = {
  }

  handlePanTilt = (x, y, z) => {
    axios.post("http://localhost:8000/api/cam/ptz", {
      x, y,z,
      token: "0"
    }).then(res => {
    
    });
  }

  render() {
    const camera = this.props.camera || {
      lat: 16.5757523,
      lng: 80.2865137,
      id: 1,
      connectionStatus: true,
    }
    return (
      <div className="bg">
        <Row type="flex" justify="start">
          <Col>
            <h2>Camera</h2>
          </Col>
        </Row>
        <Row className="bg">
          <Col span={6}>Name: Cam {camera ? camera.id : ''}</Col>
          <Col span={6}>
            Connection :{' '}
            <FontAwesomeIcon
              icon={['fas', 'circle']}
              color={camera.connectionStatus ? 'green' : 'red'}
            />{' '}
          </Col>
          <Col span={6}>#3</Col>
          <Col span={6}>Mon: ---</Col>
          <Col span={6}>Location: ---</Col>
          <Col span={6}>IP: ---</Col>
        </Row>
        <Row style={{ textAlign: 'center' }}>
          <Col className="bg">
            <video style={{"width":"600px"}} ref={this.videoRef} id="videoElem" autoPlay muted controls></video>
            {/* <img alt="camera-preview" height="300px" style={{ padding: "20px" }} src="https://www.cctvview.info/camimages/82f36608a1e888a4f480d83083ccd302.jpg" /> */}
          </Col>
        </Row>
        <Row gutter={5} style={{ textAlign: 'center' }}>
          <Col span={3}>
            <div style={{ float: 'right', height: 100, marginLeft: 70 }}>
              <Slider vertical defaultValue={30} className="bg" />
            </div>
          </Col>
          <Col span={5}>
            <Row>
              <Col span={8}></Col>
              <Col span={8}><Button size="small" onClick={() => this.handlePanTilt(0.0, 0.05, 0.0)} className="bg"><CaretUpOutlined /></Button></Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}><Button size="small" onClick={() => this.handlePanTilt(0.10, 0.0, 0.0)} className="bg"><CaretLeftOutlined /></Button></Col>
              <Col span={8}></Col>
              <Col span={8}><Button size="small" onClick={() => this.handlePanTilt(-0.10, 0.0, 0.0)} className="bg"><CaretRightOutlined /></Button></Col>
            </Row>
            <Row>
              <Col span={8}></Col>
              <Col span={8}><Button size="small" onClick={() => this.handlePanTilt(0.0, -0.05, 0.0)} className="bg"><CaretDownOutlined /></Button></Col>
              <Col span={8}></Col>
            </Row>
          </Col>
          <Col span={8} className="bg">
            <Row>
              <Col span={14}>Zoom</Col>
              <Col span={5}>
                <Button onClick={() => this.handlePanTilt(0.0, 0.00, -0.05)} size="small" className="bg">
                  <MinusOutlined />
                </Button>
              </Col>
              <Col span={5}>
                <Button onClick={() => this.handlePanTilt(0.0, 0.00, 0.05)} size="small" className="bg">
                  <PlusOutlined />
                </Button>
              </Col>
            </Row>
            <Row className="bg">
              <Col span={14}>Focus</Col>
              <Col span={5}>
                <Button size="small" className="bg">
                  N
                </Button>
              </Col>
              <Col span={5}>
                <Button size="small" className="bg">
                  F
                </Button>
              </Col>
            </Row>
            <Row className="bg">
              <Col span={14}>Iris</Col>
              <Col span={5}>
                <Button size="small" className="bg">
                  O
                </Button>
              </Col>
              <Col span={5}>
                <Button size="small" className="bg">
                  C
                </Button>
              </Col>
            </Row>
          </Col>
          <Col push={1} span={8}>
            <Row className="bg">
              <Col span={6}></Col>
              <Col span={4}>
                <Button size="small" className="bg">
                  1
                </Button>
              </Col>
              <Col span={4}>
                <Button size="small" className="bg">
                  5
                </Button>
              </Col>
              <Col span={4}>
                <Button size="small" className="bg">
                  &nbsp;&nbsp;9
                </Button>
              </Col>
              <Col span={6}></Col>
            </Row>
            <Row className="bg">
              <Col span={6}></Col>
              <Col span={4}>
                <Button size="small" className="bg">
                  2
                </Button>
              </Col>
              <Col span={4}>
                <Button size="small" className="bg">
                  6
                </Button>
              </Col>
              <Col span={4}>
                <Button size="small" className="bg">
                  10
                </Button>
              </Col>
              <Col span={6}></Col>
            </Row>
            <Row className="bg">
              <Col span={6}></Col>
              <Col span={4}>
                <Button size="small" className="bg">
                  3
                </Button>
              </Col>
              <Col span={4}>
                <Button size="small" className="bg">
                  7
                </Button>
              </Col>
              <Col span={4}>
                <Button size="small" className="bg">
                  11
                </Button>
              </Col>
              <Col span={6}></Col>
            </Row>
            <Row className="bg">
              <Col span={6}></Col>
              <Col span={4}>
                <Button size="small" className="bg">
                  4
                </Button>
              </Col>
              <Col span={4}>
                <Button size="small" className="bg">
                  8
                </Button>
              </Col>
              <Col span={4}>
                <Button size="small" className="bg">
                  12
                </Button>
              </Col>
              <Col span={6}></Col>
            </Row>
          </Col>
        </Row>
        <Row className="bg">
          <br />
        </Row>
        <Row className="bg">
          <Col span={24} className="bg">
            <CameraTable />
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(Video)
