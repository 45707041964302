import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import ParkingTable from './Table'

// import { changeTheme } from "../../utils/theme-utils"

class VMS extends Component {
  static propTypes = {}

  // componentDidMount() {
  //   changeTheme()
  // }

  render() {
    return (
      <React.Fragment>
        <Row type="flex" justify="end" className="bg">
          <Col className="bg">
            {/* <Button type="primary">
              <Icon type="plus-circle" theme="twoTone" />
              Create new incident
            </Button> */}
          </Col>
        </Row>
        <div className="bg">&nbsp;</div>
        <ParkingTable />
      </React.Fragment>
    )
  }
}

const mapStateToProps = () => ({})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(VMS)
