import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Image, Button } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
// import Icon from '@ant-design/icons';
import batteryIcon from '../../assets/icons/battery.svg'
import ECBTable from './Table'
// import { changeTheme } from "../../utils/theme-utils"

class ECB extends Component {
  static propTypes = {}

  // componentDidMount() {
  //   changeTheme()
  // }

  render() {
    const { type } = this.props;
    const buttonStyle = {
      backgroundColor: "#797979",
      color: "white", 
      width: "100%",
      height: "80px",
    };
    return (
      <React.Fragment>
        <Row type="flex" justify="start">
          <Col>
            <h2>Emergency calling box</h2>
          </Col>
        </Row>

        <Row></Row>
        <Row style={{ textAlign: 'center' }}>
          <Col>
            <Image
              alt="camera-preview"
              height="300px"
              style={{ padding: '20px' }}
              src="https://www.cctvview.info/camimages/82f36608a1e888a4f480d83083ccd302.jpg"
            />
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span="12">
            <b>Battery Status:</b>
          </Col>
          <Col>
            <img
              style={{ color: 'green', height: '18px' }}
              src={batteryIcon}
              alt=""
            />
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span="12">
            <b>Last call made:</b>
          </Col>
          <Col>11:10:00 AM 01/12/2020 10:01 sec</Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span="12">
            <b>ECB Type: </b>{type}
          </Col>
          <Col></Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span="12">
            <b>ECB Location: </b>{"Ajmer"}
          </Col>
          <Col></Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span="12">
            <b>ECB Connection Status:</b>
          </Col>
          <Col>
            <CheckCircleOutlined style={{ color: 'green' }} />
          </Col>
        </Row>
        <Row >
          <Col span={6}><Button style={{ ...buttonStyle, color: "#00FF00" }}>12<br/>Active</Button></Col>
          <Col span={6}><Button style={{ ...buttonStyle, color: "#ff0000" }}>6<br/>Inactive</Button></Col>
          <Col span={6}><Button style={{ ...buttonStyle, color: "orange" }}>3<br/>Maintenance</Button></Col>
          <Col span={6}><Button style={{ ...buttonStyle, color: "blue" }}>21<br/>All</Button></Col>
        </Row>
        <Row>
          <Col span={24}>
            <ECBTable />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = () => ({})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(ECB)
