import React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Modal, Input, Select, DatePicker } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
const { Option } = Select

function handleChange(value) {
  console.log(`selected ${value}`)
}

const CreateIncidentForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          title="Create incident"
          okText="Create"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <Form.Item label="Subject">
              {getFieldDecorator('description')(<Input />)}
            </Form.Item>
            <Form.Item label="Type">
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: 'Please input the title of collection!',
                  },
                ],
              })(
                <Select defaultValue="lucy" onChange={handleChange}>
                  <Option value="jack">Animal on Road</Option>
                  <Option value="lucy">Debris</Option>
                  <Option value="disabled">Detour</Option>
                  <Option value="Yiminghe">Emergency Vehicles</Option>
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="Location">
              {getFieldDecorator('description')(<Input type="textarea" />)}
            </Form.Item>
            <Form.Item label="Camera">
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: 'Please input the title of collection!',
                  },
                ],
              })(
                <Select defaultValue="Cam 01" onChange={handleChange}>
                  <Option value="cam01">Cam 01</Option>
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="ECB">
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: 'Please input the title of collection!',
                  },
                ],
              })(
                <Select defaultValue="" onChange={handleChange}>
                  <Option value="cam01">ECB 01</Option>
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="Time">
              {getFieldDecorator('description')(
                <DatePicker
                  style={{ width: '100%' }}
                  showTime
                  placeholder="Select Time"
                  onChange={console.log}
                  onOk={console.log}
                />,
              )}
            </Form.Item>
            <Form.Item label="Assign To">
              <Select defaultValue="lucy" onChange={handleChange}>
                <Option value="jack">Operator 1</Option>
                <Option value="lucy">Admin 1</Option>
                <Option value="ems">Emergency Services</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Description">
              <TextArea />
            </Form.Item>
          </Form>
        </Modal>
      )
    }
  },
)

export default CreateIncidentForm
