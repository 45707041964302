import React, { Component } from 'react'
import {
  Col,
  Row,
  Button,
  // Input, Select,
  // Modal, Form,
  // Radio,
  // Cascader,
  // DatePicker,
  // InputNumber,
  // TreeSelect,
  // Switch,
} from 'antd'
import AssetsTable from './Table'

// const { Search } = Input;
// const { Option } = Select;

// const tempArray = Array.from({ length: 5 }).fill(0)

class AddAssetMeta extends Component {
  state = {
    visible: false,
  }

  setVisible(visible) {
    this.setState({ visible: visible })
  }

  render() {
    return (
      <>
        <div style={{ backgroundColor: '#fff', padding: '10px' }}>
          <h3>Add New Asset Type</h3>
          <Row>
            <Col span={24}>
              <AssetsTable />
            </Col>
          </Row>
          <Row>
            <Col span={4} offset={20}>
              <Button type="primary" onClick={() => this.setVisible(true)}>
                Add Asset type
              </Button>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default AddAssetMeta
