import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Incident from '../Incident/Home'
import Camera from '../Camera'
import Vms from '../Vms'
import Parking from '../Parking'
import Beacons from '../Beacons'
import ATCC from '../ATCC'
import MetaAssets from '../MetaAssets'
import AddAssetMeta from '../MetaAssets/Add'
import Assets from '../Assets'
import AddAssets from '../Assets/Add'
import Login from '../Login'
import Dashboard from '../Dashboard'
import ECB from '../Phone'
import MapPage from '../MapPage'
import Met from '../Met'
import Reports from '../Reports'
import SifyDashboard from '../SifyDashboard/index'
import Drone from '../Drone'

/**
 * React router configuration
 */
export default () => (
  <Switch>
    {/* <Route exact path="/" component={Dashboard} /> */}
    <Route exact path="/" component={SifyDashboard} />
    <Route path="/map" component={MapPage} />
    <Route path="/login" component={Login} />
    <Route path="/cctv" component={Camera} />
    <Route path="/ims" component={Incident} />
    <Route path="/ims/add" component={Incident} />
    <Route path="/wis" component={Met} />
    <Route path="/vms" component={Vms} />
    <Route path="/parking" component={Parking} />
    <Route path="/beacons" component={Beacons} />
    <Route path="/atcc" component={ATCC} />
    <Route path="/ecb" component={ECB} />
    <Route path="/assets/add" component={AddAssets} />
    <Route path="/assets" component={Assets} />
    <Route path="/meta-assets/add" component={AddAssetMeta} />
    <Route path="/meta-assets" component={MetaAssets} />
    <Route path="/reports" component={Reports} />
    <Route path="/drone" component={Drone} />
    <Route exact="/dash-2" component={Dashboard} />
  </Switch>
)
