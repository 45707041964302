import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button } from 'antd'
import ATCCTable from './Table'
// import {changeTheme} from "../../utils/theme-utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ATCC extends Component {
  static propTypes = {}
  state = {
    atcc: {
      id: null,
      name: '---',
      location: '---, ---',
      status: '---',
      ip: '-----',
      connection: 'connected',
    },
  }

  // componentDidMount = () => {
  //   changeTheme();
  // }

  render() {
    const buttonStyle = {
      backgroundColor: "#797979",
      color: "white", 
      width: "100%",
      height: "80px",
    };

    return (
      <React.Fragment>
        <Row type="flex" justify="start">
          <Col>
            {/* <Button type="primary">
              <Icon type="plus-circle" theme="twoTone" />
              Create new incident
            </Button> */}
            <h2>ATCC</h2>
          </Col>
        </Row>
        <Row>
          <Col span={8} className="bg">
            Connection :{' '}
            <FontAwesomeIcon
              icon={['fas', 'circle']}
              color={
                this.state.atcc.connection === 'connected'
                  ? 'green'
                  : this.state.atcc.connection === '---'
                  ? 'grey'
                  : 'red'
              }
              fontSize="0.9rem"
            />
          </Col>
          <Col span={8} className="bg">
            IP : {this.state.atcc.ip}
          </Col>
          <Col span={8} className="bg">
            Location : {this.state.atcc.location}
          </Col>
        </Row>
        <Row style={{paddingTop: "10px"}}>
          <Col span={6}>
            <Row>
              <Col>
                Bike
              </Col>
            </Row>
            <Row>
              <Col>
                221
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col>
                Auto
              </Col>
            </Row>
            <Row>
              <Col>
                84
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col>
                Car
              </Col>
            </Row>
            <Row>
              <Col>
                154
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col>
                LCV
              </Col>
            </Row>
            <Row>
              <Col>
                53
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{paddingTop: "10px"}}>
          <Col span={6}>
            <Row>
              <Col>
                Trucks
              </Col>
            </Row>
            <Row>
              <Col>
                21
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col>
                MAV
              </Col>
            </Row>
            <Row>
              <Col>
                35
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col>
                Bus
              </Col>
            </Row>
            <Row>
              <Col>
                26
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col>
                Agri. Veh
              </Col>
            </Row>
            <Row>
              <Col>
                15
              </Col>
            </Row>
          </Col>
        </Row>
        <div>&nbsp;</div>
        <Row >
          <Col span={6}><Button style={{ ...buttonStyle, color: "#00FF00" }}>12<br/>Active</Button></Col>
          <Col span={6}><Button style={{ ...buttonStyle, color: "#ff0000" }}>6<br/>Inactive</Button></Col>
          <Col span={6}><Button style={{ ...buttonStyle, color: "orange" }}>3<br/>Maintenance</Button></Col>
          <Col span={6}><Button style={{ ...buttonStyle, color: "blue" }}>21<br/>All</Button></Col>
        </Row>
        <ATCCTable />
      </React.Fragment>
    )
  }
}

const mapStateToProps = () => ({})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(ATCC)
