import React, { Component } from 'react'
import { Typography, Descriptions, Row, Col, Table } from 'antd'
// import { Typography } from 'antd'
import drone from './drone.mp4'
import { columns as droneColumns } from './drone-columns.json'
const { Text, Title } = Typography

export default class Drone extends Component {
  state = {
    drone: {
      srno: 0,
      location: '---',
      dronename: '---',
      lane: '---',
      chainage: '---',
      datetime: '---',
      videoduration: '---',
      description: '---',
    },
  }
  render() {
    return (
      <div style={{ margin: '1rem 0.5rem' }}>
        <Row>
          <Col span={24}>
            <Title level={3}>Drone Video Management System</Title>
          </Col>
        </Row>
        <Row style={{ margin: '2rem auto' }}>
          <Col span={14} style={{ textAlign: 'center' }}>
            <video style={{ display: 'block', width: '90%' }} autoPlay controls>
              <source src={drone}></source>
            </video>
          </Col>
          <Col span={10}>
            <Descriptions title="Information">
              <Descriptions.Item label="Name">
                <Text type="secondary">{this.state.drone.dronename}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Location">
                <Text type="secondary">{this.state.drone.location}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Lane">
                <Text type="secondary">{this.state.drone.lane}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Chainage">
                <Text type="secondary">{this.state.drone.chainage}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Date & Time">
                <Text type="secondary">{this.state.drone.datetime}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Video Duration">
                <Text type="secondary">{this.state.drone.videoduration}</Text>
              </Descriptions.Item>
            </Descriptions>
            <Row style={{ marginTop: '1.5rem' }}>
              <Col span={24}>
                <Title level={5} style={{ margin: '0px' }}>
                  Description
                </Title>
              </Col>
              <Col span={24}>
                <Text type="secondary">{this.state.drone.description}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              onRow={(record, index) => {
                return {
                  onClick: () => {
                    this.setState({ drone: record })
                  },
                }
              }}
              dataSource={[
                {
                  srno: 1,
                  location: 'jaipur',
                  dronename: 'Drone-01',
                  lane: 'Lane 1 - CH 77+700',
                  chainage: '77.7',
                  datetime: '05/07/2021 18:59',
                  videoduration: '53 sec',
                  description:
                    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor sit sunt suscipit commodi delectus voluptas. Maiores enim aut, repudiandae at provident facilis perspiciatis, ducimus assumenda quia iusto praesentium, eos ullam officia unde nobis veniam repellendus accusantium soluta odio reiciendis? Similique, consequuntur. Commodi laudantium pariatur laborum illum nulla, harum doloribus ex!',
                  video: drone,
                },
                {
                  srno: 2,
                  location: 'jaipur',
                  dronename: 'Drone-02',
                  lane: 'Lane 2 - CH 38+300',
                  chainage: '38.3',
                  datetime: '06/07/2021 19:45',
                  videoduration: '53 sec',
                  description:
                    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo nobis iure corrupti quaerat quod sit cumque, in sint cupiditate ad? Atque quae corporis magni hic vel facilis tenetur exercitationem sit.',
                },
              ]}
              columns={droneColumns}
            />
          </Col>
        </Row>
      </div>
    )
  }
}
