import React, { useState } from 'react'
import { Col, Button, Row, Input, Select } from 'antd'
import IncidentCard from './IncidentCard'
import CreateIncidentForm from './Form'
// import { changeTheme } from "../../utils/theme-utils"

const { Search } = Input
const { Option } = Select

const tempArray = Array.from({ length: 10 }).fill(0)

const Home = () => {
  // React.useEffect(() => {
  //   changeTheme()
  // }, [])
  const [visible, setVisible] = useState(false)

  const showModal = () => {
    setVisible(true)
  }

  const handleOk = (e) => {
    console.log(e)
    setVisible(false)
  }

  const handleCancel = (e) => {
    setVisible(false)
  }
  return (
    <div style={{ backgroundColor: '#fff', padding: '10px' }} className="bg">
      <Row>
        <Col span={8}>
          <h3>Incident Managment</h3>
        </Col>

        <Col span={6} offset={8}>
          <Search
            placeholder="Search incident"
            onSearch={(value) => console.log(value)}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={2}>
          <Button onClick={showModal} type="primary">
            Create New Incident
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          fontSize: '12px',
          display: 'flex',
          alignItems: 'baseline',
          backgroundColor: '#f5f7f9',
          marginTop: '5px',
          paddingLeft: '10px',
          borderTop: '1px #cfd7df solid',
          borderBottom: '1px #cfd7df solid',
        }}
      >
        <Col style={{ alignContent: 'center' }}>
          <span>Sort By:</span>
        </Col>
        <Col className="bg-text-primary">
          <Select
            defaultValue="Date Created"
            style={{ width: 240, fontWeight: 'bold', fontSize: '12px' }}
            bordered={false}
            className="bg-text-primary"
          >
            <Option value="date">Date Created</Option>
            <Option value="priority">Priority</Option>
            <Option value="status">Status</Option>
          </Select>
        </Col>
      </Row>
      {tempArray.map(() => {
        return <IncidentCard />
      })}
      <CreateIncidentForm
        visible={visible}
        onCancel={handleCancel}
        onCreate={handleOk}
      />
    </div>
  )
}

export default Home
