import React from 'react'
import { Card, Tag, Row, Col } from 'antd'

const IncidentCard = () => {
  return (
    <Card style={{ margin: '5px' }}>
      <Row>
        <Col span={6} style={{ fontSize: '13px', fontWeight: 'bold' }}>
          Subject of the incident
        </Col>
        <Col offset={16}>
          <Tag color="#f50">High</Tag>
        </Col>
      </Row>
      <Row style={{ fontSize: '12px' }}>
        <Col span={3}>AssignedTo -&nbsp;</Col>
        <Col span={2}>Created yesterday -&nbsp;</Col>
        <Col span={2}>Due on Tomorrow</Col>
        <Col offset={22}>
          <span style={{ fontSize: '12px' }}>Incident_category</span>
        </Col>
      </Row>
    </Card>
  )
}

export default IncidentCard
