import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  // Button,
  Row,
  Col,
} from 'antd'
import { Link } from 'react-router-dom'

class AssetManagement extends Component {
  static propTypes = {}

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col span={8}>
            <h3>Asset Managment-meta</h3>
          </Col>
          <Col span={4} offset={8}>
            <Link to="/meta-assets/add">Create New Asset type</Link>
          </Col>
        </Row>
        <div>&nbsp;</div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = () => ({})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(AssetManagement)
