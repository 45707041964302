import React, { Component } from 'react'
import Map from '../Map'

export default class MapPage extends Component {
  render() {
    return (
      <>
        <Map />
      </>
    )
  }
}
