import React from 'react'
import PropTypes from 'prop-types'
import './cpu2.css'
import ReactSpeedometer from 'react-d3-speedometer'

function Cpu({ lhs = 30, rhs = 45, minValue = 0, maxValue = 100 }) {
  return (
    <div className="sify-cpul-0">
      <div className="sify-cpu-titles">Lane Speed</div>
      <div className="sify-cpu-divider-2">
        <div className="sify-cpul-years">
          <div className="sify-cpul-years1" style={{ width: '100%' }}>
            Left Hand Side
          </div>
          <div
            className="sify-cpul-years2"
            style={{ width: '100%', marginTop: '1rem' }}
          >
            <ReactSpeedometer
              width={170}
              forceRender={true}
              height={95}
              minValue={minValue}
              maxValue={maxValue}
              value={lhs}
            />
          </div>
        </div>
        <div className="sify-cpul-years">
          <div className="sify-cpul-years1" style={{ width: '100%' }}>
            Right Hand Side
          </div>
          <div
            className="sify-cpul-years2"
            style={{ width: '100%', marginTop: '1rem' }}
          >
            <ReactSpeedometer
              width={170}
              forceRender={true}
              height={95}
              minValue={minValue}
              maxValue={maxValue}
              value={rhs}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Cpu.propTypes = {
  data: PropTypes.array,
  usage: PropTypes.number,
  space: PropTypes.number,
  cpu: PropTypes.number,
}

export default Cpu
