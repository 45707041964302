import React from 'react'
import PropTypes from 'prop-types'
import './cpu.css'

function Cpu({ alarmsReceived = 9897, blackSpotsFound = 2439 }) {
  return (
    <div className="sify-cpul-0">
      <div className="sify-cpu-titles">
        Highway Incident Intelligent Processing
      </div>
      <div className="sify-cpu-divider-2">
        <div className="sify-cpul-years">
          <div className="sify-cpul-years1">Alarm Received</div>
          <div className="sify-cpul-years2">{alarmsReceived}</div>
        </div>
        <div className="sify-cpul-years">
          <div className="sify-cpul-years1">Black Spots Found</div>
          <div className="sify-cpul-years2">{blackSpotsFound}</div>
        </div>
      </div>
    </div>
  )
}

Cpu.propTypes = {
  data: PropTypes.array,
  usage: PropTypes.number,
  space: PropTypes.number,
  cpu: PropTypes.number,
}

export default Cpu
