import React from 'react'
import {
  Col,
  Row,
  // Input, Select,
  Button,
} from 'antd'
import AssetsTable from './Table'
import { Link } from 'react-router-dom'

const Assets = () => {
  return (
    <>
      <div style={{ backgroundColor: '#fff', padding: '10px' }} className="bg">
        <h3 className="bg-text-primary">Assets</h3>
        <Row gutter={[16, 16]} justify="end">
          <Col span={2} offset={20}>
            <Link to="/assets/add">
              <Button type="primary">Add Asset type</Button>
            </Link>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <AssetsTable />
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="end">
          <Col span={2} offset={20}>
            <Link to="/assets/add">
              <Button type="primary">Add Asset type</Button>
            </Link>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Assets
